<template>
  <div class="ai-home">
    <div class="sidebar">
      <div class="top-fixed">
        <div class="logo">
          <h1 class="text">copy.ai</h1>
        </div>

        <div class="new-btn">新建聊天</div>
      </div>
      
      <div class="chat-list">

      </div>
      <div class="user-sec">
        退出登录
      </div>
    </div>
    <div class="right-side">
      <div class="top-sec">

        <!-- <div class="user"></div> -->
      </div>
      <div class="main-content"></div>
      <div class="fixed-input-area">
        <textarea placeholder="输入您的问题" class="input-ctrl"></textarea>
        <span class="send">发送</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ai-home",
  data() {
    return {
      
    };
  },
  created() {
    // document.title = "溯源信息";
  },
  mounted() {
    // console.log(this.$route.params.sku);
    // const sku =
    // document.title = "溯源信息";

    // this.loadData();
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.ai-home {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .sidebar {
    width: 240px;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    background-color: #f7f7f7;
    border-right: 1px solid #ccc;
  }
  .right-side {
    flex: 1;
    // padding-left: 20px;
    .top-sec {
      height: 50px;
      border-bottom: 1px solid #ccc;
    }
    position: relative;

    .fixed-input-area {
      position: fixed;
      left: 240px;
      bottom: 50px;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .input-ctrl {
        // width: 80%;
        // flex: 1;
        padding: 10px;
        // outline: none;
      }
      .send {
        display: inline-block;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        background-color: red;
        color: red;
        // padding: ;
        // height:;
      }
    }
  }
}

.sidebar {
  position: relative;
}

.top-fixed {
  position: absolute;
  top: 30px;
  left: 20px;
  // width: 100%;
  width: 200px;
}

.chat-list {
  position: absolute;
  // background-color: red;
  top: 130px;
  left: 0;
  width: 100%;
  height: calc(100% - 130px - 90px);
  overflow: scroll;
}

.logo {
  .text {
    font-size: 32px;
    font-weight: bold;
    color: #333;
  }
}

.user-sec {
  position: absolute;
  left: 20px;
  width: 200px;
  height: 60px;
  bottom: 0;
}

.new-btn {
  widows: 100%;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #333;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    background-color: aquamarine;
    color: #f7f7f7;
    border-color: aquamarine;
  }
}
</style>